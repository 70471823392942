import React, { useState, useEffect, useRef } from 'react';
import IconSVG from '../styles/svg-icons';
import ImageSVG from '../styles/svg';
import { RadioButtonGroup, Toggle, FormControlWrapper, AnimatedValueUpdate, Checkbox, RadioButton, ProgressCircle, ButtonSwitch } from './controls';
import { TabList, TabItem } from './bidding/common/tab-list';
import { OnHoverTooltip, Preloader } from './common';
import userAvatarImage from '../images/userpic-ui-kit.png';
import { YearSelector } from './controls/YearSelector';
import { DropDownList } from './controls/DropDownList';
import { StatusMessageSectionType } from '../types/state/NotificationState';
import { StatusMessageSection } from './status-message/StatusMessageSection';
import { routes } from '../constants/routes';
import { Breadcrumbs } from './controls/breadcrumbs/Breadcrumbs';
import { BreadcrumbsItem } from './controls/breadcrumbs/BreadcrumbsItem';
import { SupportInformation } from './common/SupportInformation';
import { FilterPanel, FilterSection, FilterSwitch, FilterMultiPicker } from './filters';

function UiKit() {
    const tabsUi = {
        typography: 'typography',
        helpers: 'helpers',
        icons: 'icons',
        buttons: 'buttons',
        statuses: 'statuses',
        customControls: 'customControls',
        forms: 'forms',
        filters: 'filters',
        panels: 'panels',
        popoverTooltipModal: 'popoverTooltipModal',
        svgImages: 'svgImages',
        tables: 'tables',
    }
    const refInterval = useRef(null);
    const [iteration, setIteration] = useState(0);
    let [activeTab, setActiveTab] = useState(tabsUi.typography);

    useEffect(() => {
        refInterval.current = setInterval(() => setIteration(iteration => iteration + 1), 3000);
        return () => clearInterval(refInterval.current);
    }, []);

    const value1 = iteration;
    const value2 = Math.round(iteration / 2);
    const value3 = Math.round(iteration / 3);

    const availableYears = [2021, 2022, 2023, 2024, 2025];
    const types = [{ value: '0', text: 'Test 1', selected: true }, { value: '1', text: 'Test 2', selected: false }, { value: '3', text: 'Test 3', selected: false }]

    return (
        <>
            <div className="sub-header">
                <div className="sub-header-row type01 flex-row">
                    <h1>Projects UI library</h1>
                    <div className="flex-item-right controls">
                        Block for page title - .sub-header
                    </div>
                </div>
            </div>
            <div style={{ margin: '0 24px' }}>
                <TabList>
                    <TabItem
                        title='Typography'
                        active={activeTab === tabsUi.typography}
                        onClick={() => setActiveTab(tabsUi.typography)}
                    />
                    <TabItem
                        title='Helpers'
                        onClick={() => setActiveTab(tabsUi.helpers)}
                        active={activeTab === tabsUi.helpers}
                    />
                    <TabItem
                        title='Icons'
                        onClick={() => setActiveTab(tabsUi.icons)}
                        active={activeTab === tabsUi.icons}
                    />
                    <TabItem
                        title='Buttons'
                        onClick={() => setActiveTab(tabsUi.buttons)}
                        active={activeTab === tabsUi.buttons}
                    />
                    <TabItem
                        title='Statuses'
                        onClick={() => setActiveTab(tabsUi.statuses)}
                        active={activeTab === tabsUi.statuses}
                    />
                    <TabItem
                        title='Custom Controls'
                        onClick={() => setActiveTab(tabsUi.customControls)}
                        active={activeTab === tabsUi.customControls}
                    />
                    <TabItem
                        title='Forms'
                        onClick={() => setActiveTab(tabsUi.forms)}
                        active={activeTab === tabsUi.forms}
                    />
                    <TabItem
                        title='Filters'
                        onClick={() => setActiveTab(tabsUi.filters)}
                        active={activeTab === tabsUi.filters}
                    />
                    <TabItem
                        title='Panels'
                        onClick={() => setActiveTab(tabsUi.panels)}
                        active={activeTab === tabsUi.panels}
                    />
                    <TabItem
                        title='Popover Tooltip Modal'
                        onClick={() => setActiveTab(tabsUi.popoverTooltipModal)}
                        active={activeTab === tabsUi.popoverTooltipModal}
                    />
                    <TabItem
                        title='Svg Images'
                        onClick={() => setActiveTab(tabsUi.svgImages)}
                        active={activeTab === tabsUi.svgImages}
                    />

                    <TabItem
                        title='Tables'
                        onClick={() => setActiveTab(tabsUi.tables)}
                        active={activeTab === tabsUi.tables}
                    />
                </TabList>
            </div>
            {activeTab === tabsUi.typography &&
                <div className="container-test">
                    <h2>Typography</h2>
                    <div className="form-row form-row-inline">
                        <div className="form-item">
                            <h3>Fonts</h3>
                            <p className="font-light">Roboto Light (not using)</p>
                            <p className="font-regular">Roboto Regular</p>
                            <p className="font-medium">Roboto Medium</p>
                            <p className="font-bold">Roboto Bold</p>
                            <p className="font-black">Roboto Black (not using)</p>
                        </div>

                        <div className="form-item">
                            <h3>Base settings for text</h3>
                            <p><b>Base font:</b> Roboto Regular</p>
                            <p><b>Base font size:</b> 14px</p>
                            <p><b>Base font color:</b> #4a4a4a</p>
                        </div>
                        <div className="form-item">
                            <span className="name-tag">Tag for name or company or anything</span> - name tag<br />
                            <span className="name-tag text-ellipsis" style={{ maxWidth: '100px' }}> Long tag for name or company or anything</span>  - name tag with ...<br /><br />
                            &nbsp;&nbsp;<span className="vertical-divider display-inline" />  -  .vertical-divider
                            &nbsp;&nbsp;<span className="vertical-divider vertical-divider-light display-inline" />  -  .vertical-divider.vertical-divider-light<br /><br />
                        </div>
                    </div>

                    <div className="form-row form-row-inline">
                        <div className="form-item">
                            <h3>Titles - color: #4a4a4a; </h3>
                            <h1>H1 <span className="font-regular" style={{ color: '#4a4a4a', fontSize: '18px' }}>-&nbsp;&nbsp;&nbsp; font-size: 18px; font-family: Roboto Medium</span></h1>
                            <h1 className="normal">H1 class .normal <span className="font-regular" style={{ color: '#4a4a4a', fontSize: '18px' }}>-&nbsp; font-size: 18px; font-family: Roboto Regular</span></h1>
                            <h2>H2 <span className="font-regular" style={{ color: '#4a4a4a', fontSize: '16px' }}>-&nbsp;&nbsp;&nbsp; font-size: 16px; font-family: Roboto Medium</span></h2>
                            <h3>H3 <span className="font-regular" style={{ color: '#4a4a4a', fontSize: '14px' }}>-&nbsp;&nbsp;&nbsp; font-size: 14px; font-family: Roboto Medium;</span></h3>
                            <h3 className="title">
                                H3 class .title <span className="font-regular" style={{ color: '#4a4a4a', fontSize: '14px' }}>-&nbsp;&nbsp;&nbsp; font-size: 14px; font-family: Roboto Bold</span>
                            </h3>
                            <h4>H4 <span className="font-regular" style={{ color: '#4a4a4a', fontSize: '14px' }}>-&nbsp;&nbsp;&nbsp; font-size: 14px; font-family: Roboto Bold</span></h4>
                            <h5>H5 <span className="font-regular" style={{ color: '#4a4a4a', fontSize: '12px' }}>-&nbsp;&nbsp;&nbsp; font-size: 12px; font-family: Roboto Medium</span></h5>
                            <h5>H6 <span className="font-regular" style={{ color: '#4a4a4a', fontSize: '12px' }}>-&nbsp;&nbsp;&nbsp; font-size: 12px; font-family: Roboto Medium</span></h5>
                            <br />

                            <h4>Text Sizes</h4>
                            <p><b>.text-xl </b> - font-size: 18px</p>
                            <p><b>.text-lg </b> - font-size: 16px</p>
                            <p><b>.text-sm </b> - font-size: 12px</p>
                        </div>

                        <div className="form-item">
                            <h3>Classes for font options</h3>
                            <p><b>.text-regular </b> - font Roboto Regular</p>
                            <p><b>.text-light </b> - font Roboto Light</p>
                            <p><b>.text-medium </b> - font Roboto Medium</p>
                            <p><b>.text-bold </b> - font Roboto Bold</p>
                            <p><b>.text-black </b> - font Roboto Black</p>
                            <p><b>.text-italic </b> - font-style: italic</p>
                            <br />

                            <h4>Text Transformations</h4>
                            <p><b>.text-capitalize </b> - text transform to Capitalize</p>
                            <p><b>.text-uppercase </b> - text transform to  Uppercase</p>
                            <p><b>.text-underline </b> - text transform to Underline text</p>
                            <p><b>.text-transform-none </b> - remove text transform</p>
                            <p><b>.text-ellipsis </b> - text ellipsis styles</p>
                            <p><b>.first-letter </b> - First letter Uppercase</p>
                            <br />
                        </div>

                        <div className="form-item">
                            <h3>CLASSES FOR FONT OPTIONS</h3>
                            <h4>Text Alignmets</h4>
                            <p><b>.align-middle </b> - align Middle for items</p>
                            <p><b>.align-top </b> - align Top for items</p>
                            <p><b>.align-bottom </b> - align Bottom for items</p>
                            <p><b>.text-left </b> - align text Left</p>
                            <p><b>.text-right </b> - align text Right</p>
                            <p><b>.text-center </b> - align text Center</p>
                            <br />

                            <h4>Other text helper classes</h4>
                            <p><b>.word-break </b> - word break styles</p>
                            <p><b>.text-nowrap</b> - white-space: nowrap</p>
                            <br />

                            <h4>Color text</h4>
                            <p><b className="text-red">.text-red </b> - Color text - #f0592a</p>
                            <p><b className="text-warm-grey">.text-warm-grey </b> - Color text - #9b9b9b</p>
                            <p><b className="text-light-grey">.text-light-grey </b> - Color text - #bdbdbd</p>
                            <p><b className="text-teal-blue">.text-teal-blue </b> - Color text - #0096aa</p>
                        </div>
                    </div>

                    <h3>Lists</h3>
                    <div className="form-row form-row-inline">
                        <div className="form-item" style={{ marginBottom: '30px' }}>
                            <h3>Lists</h3>
                            <ul className="list-bullet">
                                <li>List bullet - bullet color: #0aab4e; width: 4px; height: 4px; right space: 16px;</li>
                                <li>List bullet - Class - .list-bullet</li>
                                <li>List bullet</li>
                            </ul>
                            <br />
                            <ul className="list-bullet grey">
                                <li>List bullet small - bullet color: #4a4a4a; right space: 16px;</li>
                                <li>List bullet small - Class - .list-bullet .grey</li>
                                <li>List bullet small</li>
                            </ul>
                            <br />
                            <ul className="list-bullet text-sm">
                                <li>List bullet small - bullet color: #0aab4e; width: 4px; height: 4px; right space: 16px;</li>
                                <li>List bullet small - Class - .list-bullet .text-sm</li>
                                <li>List bullet small</li>
                            </ul>
                            <br />
                            <ol>
                                <li>Standart numeric list</li>
                                <li>Standart numeric list</li>
                                <li>Standart numeric list</li>
                            </ol>
                            <br />
                            <ol className="list-alfa">
                                <li>Numeric list Alfa - Class - .list-alfa</li>
                                <li>Numeric list Alfa</li>
                            </ol>
                            <br />
                            <ol className="list-roman">
                                <li>Numeric list Roman - Class - .list-roman</li>
                                <li>Numeric list Roman</li>
                            </ol>
                            <br />
                            <ul className="contacts-cards" style={{ width: '350px' }}>
                                <li><span>Contacts cards List - Class - .contacts-cards</span></li>
                                <li>
                                    <span>First Name Last Name</span>
                                    <a href="mailto:random@mail.com">random@mail.com</a>
                                    <span>Phone</span>
                                </li>
                                <li>
                                    <span>Joe Dow</span>
                                    <a href="mailto:random@mail.com">random@mail.com</a>
                                    <span>69874566352</span>
                                </li>
                            </ul>
                            <br />
                            <p><strong>Support infromation list - .support-info-list</strong></p>
                            <SupportInformation/>
                        </div>
                        <div className="form-item">
                            <h4>Definition list - default</h4>
                            <dl className="definition-list">
                                <dt>Company Name</dt>
                                <dd>KopenTech-Seller LLC</dd>
                                <dt>User Role</dt>
                                <dd>Seller Trader</dd>
                            </dl>
                            <h4>Definition list - horizontal behaviour</h4>
                            <dl className="definition-list style01">
                                <dt>Company Name</dt>
                                <dd>KopenTech-Seller LLC</dd>
                                <dt>User Role</dt>
                                <dd>Seller Trader</dd>
                            </dl>
                        </div>
                    </div>
                    <div className="form-row form-row-inline">
                        <div className="form-item">
                            <h3>Links</h3>
                            <p><a href="/">Link</a></p>
                            <p><a href="/" className="pseudo-link">Pseudo-link</a></p>
                            <p><span className="status status-rejected"><button className="pseudo-link">Pseudo-link status</button></span></p>
                        </div>
                    </div>
                </div>
            }
            {activeTab === tabsUi.helpers &&
                <div className="container-test">
                    <h2>Helpers</h2>
                    <div className="form-row form-row-inline">
                        <div className="form-item">
                            <p><b>.container-flex</b> - for parent flex block with full height</p>
                            <p><b>.container-overflow</b> </p>
                            <p><b>.flex-row</b> - flex-direction: row; align-items: center;</p>
                            <p><b>.flex-row-full-size</b> - flex: 1 1 auto; align-items: stretch; min-height: 1px;</p>
                            <p><b>.flex-item</b> - flex: auto;</p>
                            <p><b>.flex-item-equal</b> - flex: 1 1 50%;</p>
                            <p><b>.flex-none</b> - flex: none;</p>
                            <p><b>.flex-item-right</b> - item right position with right align text</p>
                            <p><b>.flex-align-end</b> - align-self: flex-end</p>
                            <p><b>.flex-wrap</b> - flex-wrap: wrap</p>
                            <p><b>.flex-direction-row</b> - flex-direction: row</p>
                            <p><b>.flex-direction-column</b> - flex-direction: column</p>
                            <p><b>.align-items-flex-start</b> - align-items: flex-start</p>
                            <p><b>.align-items-flex-end</b> - align-items: flex-end</p>
                            <p><b>.justify-content-end</b> - justify-content: flex-end</p>
                            <p><b>.justify-content-space-between</b> - justify-content: space-between</p>
                        </div>
                        <div className="form-item">
                            <p><b>.display-inline</b></p>
                            <p><b>.display-inline-flex</b></p>
                            <p><b>.display-none</b></p>
                            <p><b>.visibility-none</b></p>
                            <p><b>.position-relative</b></p>
                            <p><b>.position-absolute</b></p>
                            <p><b>.vertical-align-top</b></p>
                            <p><b>.transition</b> - for effects</p>
                            <p><b>.pointer-events-none</b> - pointer-events: none</p>
                            <p><b>.pointer</b> - cursor: pointer</p>
                            <p><b>.overflow-hidden</b> - overflow: hidden</p>
                            <p><b>.overflow-auto</b> - overflow: auto</p>
                            <p><b>.overflow-visible</b> - overflow: visible</p>
                            <br/>
                            <h3>Paddings/Margins</h3>
                            <p><b>.width-64</b> - width: 64px</p>
                            <p><b>.width-100</b> - width: 100%</p>
                            <p><b>.width-a</b> - width: auto</p>
                        </div>
                    </div>
                    <h3>Paddings/Margins</h3>
                    <div className="form-row form-row-inline">
                        <div className="form-item">
                            <p><b>.margin-t-0</b> - margin-top:0</p>
                            <p><b>.margin-r-0</b> - margin-right:0</p>
                            <p><b>.margin-b-0</b> - margin-bottom:0</p>
                            <p><b>.margin-l-0</b> - margin-left:0</p>
                            <p><b>.margin-l-a</b> - margin-left:auto</p>
                            <p><b>.margin-r-8</b> - margin-right:8px</p>
                            <p><b>.margin-r-a</b> - margin-right:auto</p>
                            <p><b>.margin-l-8</b> - margin-left:8px</p>
                            <p><b>.margin-b-8</b> - margin-bottom:8px</p>
                            <p><b>.padding-r-0</b> - padding-right:0</p>
                            <p><b>.padding-l-0</b> - padding-left:0</p>
                        </div>
                        <div className="form-item">
                        </div>
                    </div>
                </div>
            }
            {activeTab === tabsUi.icons &&
                <div className="container-test icons-test">
                    <h2>Icons</h2>
                    <h3>Classes for sizing Icons:</h3>
                    <p><b>.icon.icon-sm</b> - width: 12px; height: 12px;</p>
                    <p><b>.icon.icon-xl</b> - width: 24px; height: 24px;</p>
                    <br />
                    <p><b>.icon-svg.icon-svg-sm</b> - transform: scale(0.75) - width: 12px; height: 12px;</p>
                    <p><b>.icon-svg.icon-svg-xl</b> - transform: scale(1.5) - width: 24px; height: 24px;</p>
                    <br />
                    <br />

                    <div className="form-row form-row-inline">
                        <div className="form-item">
                            <h3>Logotypes</h3>
                            <i className="icon icon-logo-bwic" style={{ width: '104px', height: '41px' }}></i>&nbsp;&nbsp;&nbsp;  icon icon-logo-bwic (width: 104px, height: 41px) <br />
                            <i className="icon icon-logo-amr" style={{ width: '105px', height: '40px' }}></i>&nbsp;&nbsp;&nbsp;  icon icon-logo-amr (width: 105px, height: 40px) <br />
                            <br />

                            <h3>Avatars</h3>
                            <div className="user-avatar align-middle">
                                <span>JD</span>
                            </div>
                            &nbsp;&nbsp;&nbsp;
                            <div className="user-avatar align-middle" style={{ fontSize: '96px' }}>
                                <span>JD</span>
                            </div>
                            &nbsp;&nbsp;&nbsp;
                            <div className="user-avatar align-middle" style={{ fontSize: '64px' }}>
                                <img src={userAvatarImage} alt="" />
                            </div>
                        </div>

                        <div className="form-item">
                            <h3>Loaders</h3>
                            <div className="display-inline" style={{ width: '100px', height: '100px', position: 'relative' }}>
                                <Preloader inProgress={true} />
                            </div> - default fullScreen Preloader
                            <br /><br />
                            <div className="display-inline">
                                <Preloader small={true} fullScreen={false} inProgress={true} />
                            </div> - small Preloader fullScreen=false
                            <br /><br />
                            <div className="display-inline">
                                <Preloader small={true} fullScreen={false} inProgress={true} text="Exporting..." />
                            </div> - small Preloader fullScreen=false with text
                            <br /><br />
                            <div className="display-inline">
                                <Preloader textOnly={true} fullScreen={false} inProgress={true} text="Exporting..." />
                            </div> - Preloader text only

                            <br />
                            <br />
                            <br />

                            <h3>Loader (IE only view)</h3>
                            <i className="icon icon-loader-lg"></i>&nbsp;&nbsp;&nbsp;  icon icon-loader-lg (width: 48px, height: 48px)<br />
                            <i className="icon icon-loader-sm"></i>&nbsp;&nbsp;&nbsp;  icon icon-loader-sm (width: 16px, height: 16px)<br />
                            <br /><br />
                            <h3>Progress</h3>
                            <ProgressCircle progress={35} /> - .progress-circle (width: 16px, height: 16px)
                        </div>
                    </div>

                    <br />
                    <br />

                    <h2>Icons as backgrounds (old style)</h2>

                    <div className="form-row form-row-inline">
                        <div className="form-item">
                            <h3>Files</h3>
                            <i className="icon icon-xl icon-add-file"></i>&nbsp; &nbsp; &nbsp; icon icon-add-file (width: 24px, height: 24px)<br />
                            <i className="icon icon-xl icon-pdf"></i>&nbsp; &nbsp; &nbsp; icon icon-pdf (width: 24px, height: 24px)<br />
                            <i className="icon icon-xl icon-jpg"></i>&nbsp;&nbsp;&nbsp;  icon icon-jpg (width: 24px, height: 24px)<br />
                            <i className="icon icon-xl icon-xls"></i>&nbsp;&nbsp;&nbsp;  icon icon-xls (width: 24px, height: 24px)<br />
                            <i className="icon icon-xl icon-xlsx"></i>&nbsp;&nbsp;&nbsp;  icon icon-xlsx (width: 24px, height: 24px)<br />
                            <i className="icon icon-xl icon-doc"></i>&nbsp;&nbsp;&nbsp;  icon icon-doc (width: 24px, height: 24px)<br />
                            <i className="icon icon-xl icon-docx"></i>&nbsp;&nbsp;&nbsp;  icon icon-docx (width: 24px, height: 24px)<br />
                            <i className="icon icon-xl icon-csv"></i>&nbsp;&nbsp;&nbsp;  icon icon-csv (width: 24px, height: 24px)<br />
                            <i className="icon icon-xl icon-ppt"></i>&nbsp;&nbsp;&nbsp;  icon icon-ppt (width: 24px, height: 24px)<br />
                            <i className="icon icon-xl icon-pptx"></i>&nbsp;&nbsp;&nbsp;  icon icon-pptx (width: 24px, height: 24px)<br />
                            <i className="icon icon-xl icon-cdi"></i>&nbsp;&nbsp;&nbsp;  icon icon-cdi (width: 24px, height: 24px)<br />
                            <i className="icon icon-xl icon-failed"></i>&nbsp;&nbsp;&nbsp;  icon icon-failed (width: 24px, height: 24px)<br />
                            <i className="icon icon-xl icon-unknown-file"></i>&nbsp;&nbsp;&nbsp;  icon icon-unknown-file (width: 24px, height: 24px)<br />
                            <br />

                            <h3>Arrows & Marks</h3>
                            <i className="icon icon-header-menu"></i>&nbsp;&nbsp;&nbsp;  icon icon-header-menu (width: 9px, height: 6px)<br />
                            <i className="icon icon-expand"></i>&nbsp;&nbsp;&nbsp;  icon icon-expand (width: 8px, height: 11px)<br />
                            <i className="icon icon-expand is-open"></i>&nbsp;&nbsp;&nbsp;  icon icon-expand is-open (width: 10px, height: 7px)<br />
                            <i className="icon icon-expand warm-grey"></i>&nbsp;&nbsp;&nbsp;  icon icon-expand warm-grey (width: 8px, height: 11px)<br />
                            <i className="icon icon-expand pinkish-grey"></i>&nbsp;&nbsp;&nbsp;  icon icon-expand warm-grey (width: 8px, height: 11px)<br />
                            <i className="icon icon-sm icon-mark-big"></i>&nbsp;&nbsp;&nbsp;  icon icon-mark-big (width: 14px, height: 11px)<br />
                            <i className="icon icon-arrow-teal"></i>&nbsp;&nbsp;&nbsp;  icon icon-arrow-teal (width: 11px, height: 6px)<br />
                            <i className="icon icon-arrow-teal is-open"></i>&nbsp;&nbsp;&nbsp;  icon icon-arrow-teal is-open (width: 11px, height: 6px)<br />
                            <i className="icon icon-sync-success"></i>&nbsp;&nbsp;&nbsp;  icon icon-sync-success (width: 18px, height: 20px)<br />
                            <i className="icon icon-sync-reject"></i>&nbsp;&nbsp;&nbsp;  icon icon-sync-reject (width: 18px, height: 20px)<br />
                            <br />
                        </div>
                        <div className="form-item">
                            <h3>Status messages</h3>
                            <i className="icon icon-success"></i>&nbsp; &nbsp; &nbsp; icon icon-success (width: 16px, height: 16px)<br />
                            <i className="icon icon-info"></i>&nbsp;&nbsp;&nbsp;  icon icon-info (width: 16px, height: 16px)<br />
                            <i className="icon icon-error"></i>&nbsp;&nbsp;&nbsp;  icon icon-error (width: 16px, height: 16px)<br />
                            <i className="icon icon-warning"></i>&nbsp;&nbsp;&nbsp;  icon icon-warning (width: 16px, height: 16px)<br />
                            <i className="icon icon-warning yellow"></i>&nbsp;&nbsp;&nbsp;  icon icon-warning yellow (width: 16px, height: 16px)<br />
                            <i className="icon icon-warning-toast icon-xl"></i>&nbsp;&nbsp;&nbsp;  icon icon-warning-toast (width: 24px, height: 24px)<br />
                            <i className="icon icon-bell-toast icon-xl"></i>&nbsp;&nbsp;&nbsp;  icon icon-bell-toast (width: 24px, height: 24px)<br />
                            <br />

                            <h3>Others</h3>
                            <i className="icon icon-search"></i>&nbsp;&nbsp;&nbsp;  icon icon-search (width: 16px, height: 16px)<br />
                            <i className="icon icon-settings"></i>&nbsp;&nbsp;&nbsp;  icon icon-settings (width: 16px, height: 16px)<br />
                            <i className="icon icon-delete"></i>&nbsp;&nbsp;&nbsp;  icon icon-delete (width: 16px, height: 16px)<br />
                            <i className="icon icon-calendar"></i>&nbsp;&nbsp;&nbsp;  icon icon-calendar (width: 16px, height: 16px)<br />
                            <i className="icon icon-calendar-grey"></i>&nbsp; &nbsp; &nbsp; icon icon-calendar-grey (width: 16px, height: 16px)<br />
                            <i className="icon icon-lock"></i>&nbsp;&nbsp;&nbsp;  icon icon-lock (width: 16px, height: 16px)<br />
                            <i className="icon icon-submit"></i>&nbsp;&nbsp;&nbsp;  icon icon-submit (width: 16px, height: 16px)<br />
                            <i className="icon icon-profile"></i>&nbsp;&nbsp;&nbsp;  icon icon-profile (width: 16px, height: 16px)<br />
                            <i className="icon icon-primary-contact"></i>&nbsp;&nbsp;&nbsp;  icon icon-primary-contact (width: 16px, height: 16px)<br />
                            <i className="icon icon-sign-out"></i>&nbsp;&nbsp;&nbsp;  icon icon-sign-out (width: 16px, height: 16px)<br />
                            <i className="icon icon-time"></i>&nbsp;&nbsp;&nbsp;  icon icon-time (width: 16px, height: 16px)<br />
                            <i className="icon icon-time-blue"></i>&nbsp;&nbsp;&nbsp;  icon icon-time-blue (amr) (width: 16px, height: 16px)<br />
                            <i className="icon icon-xl icon-time-gray"></i>&nbsp;&nbsp;&nbsp;  icon icon-time-gray (width: 24px, height: 24px)<br />
                            <i className="icon icon-traded"></i>&nbsp; &nbsp; &nbsp; icon icon-traded (width: 16px, height: 16px)<br />
                            <i className="icon icon-email"></i>&nbsp;&nbsp;&nbsp;  icon icon-email (width: 16px, height: 16px)<br />
                            <i className="icon icon-sms"></i>&nbsp;&nbsp;&nbsp;  icon icon-sms (width: 16px, height: 16px)<br />
                            <br />

                            <h3>Zendesk help-resources</h3>
                            <i className="icon icon-product-intro"></i>&nbsp; &nbsp; &nbsp; icon icon-product-intro (width: 24px, height: 24px)<br />
                            <i className="icon icon-product-layer"></i>&nbsp; &nbsp; &nbsp; icon icon-product-layer (width: 24px, height: 24px)<br />
                            <i className="icon icon-product-search"></i>&nbsp; &nbsp; &nbsp; icon icon-product-search (width: 24px, height: 24px)<br />
                            <i className="icon icon-product-canvas"></i>&nbsp; &nbsp; &nbsp; icon icon-product-canvas (width: 24px, height: 24px)<br />
                            <i className="icon icon-product-howto"></i>&nbsp; &nbsp; &nbsp; icon icon-product-howto (width: 24px, height: 24px)<br />
                            <i className="icon icon-attached"></i>&nbsp; &nbsp; &nbsp; icon icon-attached (width: 8px, height: 16px)<br />
                        </div>
                    </div>

                    <h2>SVG Icons</h2>

                    <div className="form-row form-row-inline">
                        <div className="form-item">
                            <h3>Payment</h3>
                            <IconSVG name="american-express" width={74} height={26} />&nbsp;&nbsp;&nbsp; name="american-express" width={74} height={26} <br />
                            <IconSVG name="dinersclub" width={98} height={26} />&nbsp;&nbsp;&nbsp; name="dinersclub" width={98} height={26} <br />
                            <IconSVG name="discover" width={72} height={12} />&nbsp;&nbsp;&nbsp; name="discover" width={72} height={12} <br />
                            <IconSVG name="jcb" width={37} height={28} />&nbsp;&nbsp;&nbsp; name="jcb" width={37} height={28} <br />
                            <IconSVG name="mastercard" width={44} height={34} />&nbsp;&nbsp;&nbsp; name="mastercard" width={44} height={34} <br />
                            <IconSVG name="unionpay" width={48} height={30} />&nbsp;&nbsp;&nbsp; name="unionpay" width={48} height={30} <br />
                            <IconSVG name="visa" width={49} height={15} />&nbsp;&nbsp;&nbsp; name="visa" width={49} height={15} <br />
                            <IconSVG name="add-payment" width="26" height="18" />&nbsp;&nbsp;&nbsp; name="add-payment" width={26} height={18} <br />
                            <IconSVG name="payment-card" width={22} height={15} />&nbsp;&nbsp;&nbsp; name="payment-card" width={22} height={15} <br />
                            <br />

                            <h3>Different sizes</h3>
                            <IconSVG name="upload-doc" width={24} height={24} /> &nbsp;&nbsp;&nbsp; name="upload-doc" width={24} height={24} <br />
                            <IconSVG name="subscribe-lock" width={24} height={24} />&nbsp;&nbsp;&nbsp; name="subscribe-lock" width={24} height={24} <br />
                            <IconSVG name="subscribe-lock-grey" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="subscribe-lock-grey" width={16} height={16} <br />
                            <IconSVG name="trial-enabled" width={24} height={24} />&nbsp;&nbsp;&nbsp; name="trial-enabled" width={24} height={24} <br />
                            <IconSVG name="trial-expired" width={24} height={24} />&nbsp;&nbsp;&nbsp; name="trial-expired" width={24} height={24} <br />
                            <IconSVG name="gift" width={64} height={62} />&nbsp;&nbsp;&nbsp; name="gift" width={64} height={62} <br />
                            <br />

                            <h3>Header</h3>
                            <IconSVG name="whats-new" width={24} height={24} />&nbsp;&nbsp;&nbsp; name="whats-new" width={24} height={24} <br />
                            <IconSVG name="feedback" width={24} height={24} />&nbsp;&nbsp;&nbsp; name="feedback" width={24} height={24} <br />
                            <IconSVG name="notifications" width={24} height={24} />&nbsp;&nbsp;&nbsp; name="notifications" width={24} height={24} <br />
                            <IconSVG name="support" width={24} height={24} />&nbsp;&nbsp;&nbsp; name="support" width={24} height={24} <br />
                            <br />

                            

                            <h3>User</h3>
                            <IconSVG name="distrib" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="distrib" width={16} height={16} <br />
                            <IconSVG name="syndicate" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="syndicate" width={16} height={16} <br />
                            <IconSVG name="primary-contact" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="primary-contact" width={16} height={16} <br />
                            <IconSVG name="head-of-trade" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="head-of-trade" width={16} height={16} <br />
                            <IconSVG name="addParticipant" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="addParticipant" width={16} height={16} <br />
                            <IconSVG name="user-edit" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="user-edit" width={16} height={16} <br />
                            <IconSVG name="bid-on-behalf" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="bid-on-behalf" width={16} height={16} <br />
                            <IconSVG name="head-of-desk" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="head-of-desk" width={16} height={16} <br />
                            <br />

                            <h3>Social</h3>
                            <IconSVG name="linkedin" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="linkedin" width={16} height={16} <br />
                            <IconSVG name="twitter" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="twitter" width={16} height={16} <br />
                            <IconSVG name="linkedin-filled" width={16} height={16} />&nbsp; &nbsp; &nbsp; name="linkedin-filled" width={16} height={16} <br />
                            <br />

                            <h3>Markdown/editor</h3>
                            <IconSVG name="editor-italic" width={16} height={16} />&nbsp; &nbsp; &nbsp; name="editor-italic" width={16} height={16} <br />
                            <IconSVG name="editor-bold" width={16} height={16} />&nbsp; &nbsp; &nbsp; name="editor-bold" width={16} height={16} <br />
                            <IconSVG name="editor-underline" width={16} height={16} />&nbsp; &nbsp; &nbsp; name="editor-underline" width={16} height={16} <br />
                            <IconSVG name="editor-strikethrough" width={16} height={16} />&nbsp; &nbsp; &nbsp; name="editor-strikethrough" width={16} height={16} <br />
                            <IconSVG name="editor-numbered-list" width={16} height={16} />&nbsp; &nbsp; &nbsp; name="editor-numbered-list" width={16} height={16} <br />
                            <IconSVG name="editor-ordered-list" width={16} height={16} />&nbsp; &nbsp; &nbsp; name="editor-ordered-list" width={16} height={16} <br />
                            <br />

                            <h3>Auto / Manual</h3>
                            <IconSVG name="auto" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="auto" width={16} height={16} <br />
                            <IconSVG name="manual" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="manual" width={16} height={16} <br />
                            <br />
                        </div>
                        <div className="form-item">
                            <h3>Arrows / Plus / Cross</h3>
                            <IconSVG name="corner-arrow" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="corner-arrow" width={16} height={16} <br />
                            <IconSVG name="icon-expand" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="icon-expand" width={16} height={16} <br />
                            <IconSVG name="check" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="check" width={16} height={16} <br />
                            <IconSVG name="saved" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="saved" width={16} height={16} <br />
                            <IconSVG name="affirm" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="affirm" width={16} height={16} <br />
                            <IconSVG name="button-arrow" width={10} height={10} />&nbsp;&nbsp;&nbsp; name="button-arrow" width={10} height={10} <br />
                            <IconSVG name="button-arrow-big" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="button-arrow-big" width={16} height={16} <br />
                            <IconSVG name="icon-double-arrows" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="icon-double-arrows" width={16} height={16} <br />
                            <IconSVG className="collapse" name="icon-double-arrows" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="icon-double-arrows" collapse width={16} height={16} <br />
                            <IconSVG name="minus" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="minus" width={16} height={16} <br />
                            <IconSVG name="plus" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="plus" width={16} height={16} <br />
                            <IconSVG name="plus-sm" width={12} height={12} />&nbsp;&nbsp;&nbsp; name="plus-sm" width={12} height={12} <br />
                            <IconSVG name="plus-round" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="plus-round" width={16} height={16} <br />
                            <IconSVG name="add-contour" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="add-contour" width={16} height={16} <br />
                            <IconSVG name="close" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="close" width={16} height={16} <br />
                            <IconSVG name="export" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="export" width={16} height={16} <br />
                            <IconSVG name="import" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="import" width={16} height={16} <br />
                            <IconSVG name="undo" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="undo" width={16} height={16} <br />
                            <IconSVG name="unpublish" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="unpublish" width={16} height={16} <br />
                            <IconSVG name="upload" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="upload" width={16} height={16} <br />
                            <IconSVG name="downloadTemplate" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="downloadTemplate" width={16} height={16} <br />
                            <IconSVG name="saving" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="saving" width={16} height={16} <br />
                            <IconSVG name="trading" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="trading" width={16} height={16} <br />
                            <IconSVG name="collapse-left" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="collapse-left" width={16} height={16} <br />
                            <IconSVG name="arrow-up-big" width={16} height={16} />&nbsp; &nbsp; &nbsp; name="arrow-up-big" width={16} height={16} <br />
                            <IconSVG name="arrow-increase" width={16} height={16} />&nbsp; &nbsp; &nbsp; name="arrow-increase" width={16} height={16} <br />
                            <IconSVG name="arrow-decrease" width={16} height={16} />&nbsp; &nbsp; &nbsp; name="arrow-decrease" width={16} height={16} <br />

                            
                            <br />

                            <h3>Info / Alert / Help</h3>
                            <IconSVG name="info" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="info" width={16} height={16} <br />
                            <IconSVG name="warning" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="warning" width={16} height={16} <br />
                            <IconSVG name="help" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="help" width={16} height={16} <br />
                            <br />

                            <h3>Platform</h3>
                            <IconSVG name="on-platform" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="on-platform" width={16} height={16} <br />
                            <IconSVG name="off-platform" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="off-platform" width={16} height={16} <br />
                            <br />

                            <h3>Eye</h3>
                            <IconSVG name="hidden" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="hidden" width={16} height={16} <br />
                            <IconSVG name="view" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="view" width={16} height={16} <br />
                            <br />

                            <h3>Favorite</h3>
                            <IconSVG name="favorite" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="favorite" width={16} height={16} <br />
                            <IconSVG name="favorite-fill" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="favorite-fill" width={16} height={16} <br />
                            <br />

                            <h3>Bidding</h3>

                            <IconSVG name="bid" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="bid" width={16} height={16} <br />
                            <IconSVG name="traded" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="traded" width={16} height={16} <br />
                            <IconSVG name="double-bid" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="double-bid" width={16} height={16} <br />
                            <br />

                            <h3>Envelope</h3>
                            <IconSVG name="blast-message" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="blast-message" width={16} height={16} <br />
                            <IconSVG name="email" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="email" width={16} height={16} <br />
                            <IconSVG name="follow-up" width={16} height={16} />&nbsp; &nbsp; &nbsp; name="follow-up" width={16} height={16} <br />
                            <br />
                        </div>
                        <div className="form-item">
                            <h3>File</h3>
                            <IconSVG name="file" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="file" width={16} height={16} <br />
                            <IconSVG name="manage" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="manage" width={16} height={16} <br />
                            <IconSVG name="go-to" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="go-to" width={16} height={16} <br />
                            <IconSVG name="settlement-templates" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="settlement-templates" width={16} height={16} <br />
                            <IconSVG name="copy" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="copy" width={16} height={16} <br />
                            <IconSVG name="user-agreement" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="user-agreement" width={16} height={16} <br />
                            <br />

                            <h3>Others</h3>
                            <IconSVG name="new-portfolio" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="new-portfolio" width={16} height={16} <br />
                            <IconSVG name="settings" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="settings" width={16} height={16} <br />
                            <IconSVG name="filter-settings" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="filter-settings" width={16} height={16} <br />
                            <IconSVG name="more" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="more" width={16} height={16} <br />
                            <IconSVG name="chat" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="chat" width={16} height={16} <br />
                            <IconSVG name="rules" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="rules" width={16} height={16} <br />
                            <IconSVG name="axed" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="axed" width={16} height={16} <br />
                            <IconSVG name="final" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="final" width={16} height={16} <br />
                            <IconSVG name="edit-pencil" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="edit-pencil" width={16} height={16} <br />
                            <IconSVG name="stats" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="stats" width={16} height={16} <br />
                            <IconSVG name="sidebar-expand" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="sidebar-expand" width={16} height={16} <br />
                            <IconSVG name="photo" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="photo" width={16} height={16} <br />
                            <IconSVG name="active" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="active" width={16} height={16} <br />
                            <IconSVG name="clearing" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="clearing" width={16} height={16} <br />
                            <IconSVG name="idea" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="idea" width={16} height={16} <br />
                            <IconSVG name="bug" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="bug" width={16} height={16} <br />
                            <IconSVG name="timeline" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="timeline" width={16} height={16} <br />
                            <IconSVG name="company" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="company" width={16} height={16} <br />
                            <IconSVG name="drag-dots" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="drag-dots" width={16} height={16} <br />
                            <IconSVG name="grid-swap" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="grid-swap" width={16} height={16} <br />
                            <IconSVG name="graph" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="graph" width={16} height={16} <br />
                            <IconSVG name="cross-graph" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="cross-graph" width={16} height={16} <br />
                            <IconSVG name="intex" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="intex" width={16} height={16} <br />
                            <IconSVG name="save" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="save" width={16} height={16} <br />
                            <IconSVG name="alert" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="alert" width={16} height={16} <br />
                            <IconSVG name="alert-off" width={16} height={16} />&nbsp; &nbsp; &nbsp; name="alert-off" width={16} height={16} <br />
                            <IconSVG name="commission" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="commission" width={16} height={16} <br />
                            <IconSVG name="same-day" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="same-day" width={16} height={16} <br />
                            <IconSVG name="related-transaction" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="related-transaction" width={16} height={16} <br />
                            <IconSVG name="timer" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="timer" width={16} height={16} <br />
                            <IconSVG name="calendar" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="calendar" width={16} height={16} <br />
                            <IconSVG name="showLess" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="showLess" width={16} height={16} <br />
                            <IconSVG name="showMore" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="showMore" width={16} height={16} <br />
                            <IconSVG name="basket" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="basket" width={16} height={16} <br />
                            <IconSVG name="collapse-right" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="collapse-right" width={16} height={16} <br />
                            <IconSVG name="flag" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="flag" width={16} height={16} <br />
                            <IconSVG name="phone" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="phone" width={16} height={16} <br />
                            <IconSVG name="share" width={16} height={16} />&nbsp;&nbsp;&nbsp; name="share" width={16} height={16} <br />
                            <IconSVG name="bloomberg" width={16} height={16} />&nbsp; &nbsp; &nbsp; name="bloomberg" width={16} height={16} <br />
                            <IconSVG name="valitana" width={16} height={16} />&nbsp; &nbsp; &nbsp; name="valitana" width={16} height={16} <br />
                            <IconSVG name="website" width={16} height={16} />&nbsp; &nbsp; &nbsp; name="website" width={16} height={16} <br />
                            <IconSVG name="external-link" width={16} height={16} />&nbsp; &nbsp; &nbsp; name="external-link" width={16} height={16} <br />
                            <IconSVG name="active-manager" width={16} height={16} />&nbsp; &nbsp; &nbsp; name="active-manager" width={16} height={16} <br />
                            <IconSVG name="address-book" width={16} height={16} />&nbsp; &nbsp; &nbsp; name="address-book" width={16} height={16} <br />
                            <IconSVG name="go-to-details" width={16} height={16} />&nbsp; &nbsp; &nbsp; name="go-to-details" width={16} height={16} <br />
                            <IconSVG name="paperclip" width={16} height={16} />&nbsp; &nbsp; &nbsp; name="paperclip" width={16} height={16} <br />
                            <IconSVG name="sign-out" width={16} height={16} />&nbsp; &nbsp; &nbsp; name="sign-out" width={16} height={16} <br />
                            <IconSVG name="kopentech" width={16} height={16} />&nbsp; &nbsp; &nbsp; name="kopentech" width={16} height={16} <br />
                            <IconSVG name="docs" width={16} height={16} />&nbsp; &nbsp; &nbsp; name="docs" width={16} height={16} <br />

                            <br />
                        </div>
                    </div>
                </div>
            }
            {activeTab === tabsUi.buttons &&
                <div className="container-test">
                    <h2>Buttons</h2>
                    <div className="buttons-uikit">
                        <div className="form-row form-row-inline">
                            <div className="form-item">
                                <button className="btn btn-main">btn btn-main</button><br /><br />
                                <button className="btn btn-main btn-no-uppercase">btn btn-main btn-no-uppercase</button><br /><br />
                                <button className="btn btn-main"><IconSVG name="stats" width="16" height="16" /> btn btn-main</button><br /><br />
                                <button className="btn btn-main" disabled>btn btn-main</button><br /><br />
                                <button className="btn btn-main btn-sm">btn btn-main btn-sm</button>
                            </div>
                            <div className="form-item">
                                <button className="btn btn-ghost">btn btn-ghost</button><br /><br />
                                <button className="btn btn-ghost btn-no-uppercase">btn btn-ghost btn-no-uppercase</button><br /><br />
                                <button className="btn btn-ghost"><IconSVG name="stats" width="16" height="16" /> btn btn-ghost</button><br /><br />
                                <button className="btn btn-ghost" disabled>btn btn-ghost</button><br /><br />
                                <button className="btn btn-ghost btn-sm">btn btn-ghost btn-sm</button>
                            </div>
                            <div className="form-item">
                                <button className="btn btn-ghost btn-ghost-secondary">btn btn-ghost btn-ghost-secondary</button><br /><br />
                                <button className="btn btn-ghost btn-ghost-secondary btn-no-uppercase">btn btn-ghost btn-ghost-secondary btn-no-uppercase</button><br /><br />
                                <button className="btn btn-ghost btn-ghost-secondary"><IconSVG name="stats" width="16" height="16" /> btn btn-ghost btn-ghost-secondary</button><br /><br />
                                <button className="btn btn-ghost btn-ghost-secondary" disabled>btn btn-ghost btn-ghost-secondary</button><br /><br />
                                <button className="btn btn-ghost btn-ghost-secondary btn-sm">btn btn-ghost btn-ghost-secondary btn-sm</button>
                            </div>
                            <div className="form-item">
                                <button className="btn btn-main btn-danger">btn btn-main btn-danger</button><br /><br />
                                <button className="btn btn-main btn-danger btn-no-uppercase">btn btn-main btn-danger btn-no-uppercase</button><br /><br />
                                <button className="btn btn-main btn-danger"><IconSVG name="stats" width="16" height="16" /> btn btn-main btn-danger</button><br /><br />
                                <button className="btn btn-main btn-danger" disabled>btn btn-main btn-danger</button><br /><br />
                                <button className="btn btn-main btn-danger btn-sm">btn btn-main btn-danger btn-sm</button>
                            </div>
                            <div className="form-item">
                                <button className="btn btn-ghost btn-danger" type="button">btn btn-ghost btn-danger</button><br /><br />
                                <button className="btn btn-ghost btn-danger btn-no-uppercase" type="button">btn btn-ghost btn-danger btn-no-uppercase</button><br /><br />
                                <button className="btn btn-ghost btn-danger" type="button"><IconSVG name="stats" width="16" height="16" /> btn btn-ghost btn-danger</button><br /><br />
                                <button className="btn btn-ghost btn-danger" type="button" disabled>btn btn-ghost btn-danger</button><br /><br />
                                <button className="btn btn-ghost btn-danger btn-sm" type="button" disabled>btn btn-ghost btn-danger btn-sm</button>
                            </div>
                            <div className="form-item">
                                <button className="btn btn-ghost btn-arrow" type="button">
                                    <IconSVG name="icon-expand" width={16} height={16} />
                                </button>&nbsp;
                                <button className="btn btn-main btn-arrow" type="button">
                                    <IconSVG name="icon-expand" width={16} height={16} />
                                </button><br /><br /><br /><br />
                                <button className="btn btn-ghost btn-arrow btn-arrow-mirror" type="button">
                                    <IconSVG name="icon-expand" width={16} height={16} />
                                </button>&nbsp;
                                <button className="btn btn-main btn-arrow btn-arrow-mirror" type="button">
                                    <IconSVG name="icon-expand" width={16} height={16} />
                                </button><br /><br />
                                <button className="btn btn-ghost btn-sm btn-arrow" type="button">
                                    <IconSVG name="icon-expand" width={16} height={16} />
                                </button>&nbsp;
                                <button className="btn btn-main btn-sm btn-arrow" type="button">
                                    <IconSVG name="icon-expand" width={16} height={16} />
                                </button><br /><br />
                                <button className="btn btn-ghost btn-sm btn-arrow btn-arrow-mirror" type="button">
                                    <IconSVG name="icon-expand" width={16} height={16} />
                                </button>&nbsp;
                                <button className="btn btn-main btn-sm btn-arrow btn-arrow-mirror" type="button">
                                    <IconSVG name="icon-expand" width={16} height={16} />
                                </button><br /><br />
                            </div>
                        </div>
                        <div className="form-row form-row-inline">
                            <div className="form-item">
                                <button className="btn btn-link">btn-link - medium font(default)</button><br /><br />
                                <button className="btn btn-link regular">btn-link regular - regular font</button><br /><br />
                                <button className="btn btn-link btn-link-lg">btn-link btn-link-lg - font 14px/medium</button><br /><br />
                                <button className="btn btn-link"><IconSVG name="stats" width="16" height="16" /> btn-link</button><br /><br />
                                <button className="btn-link btn-link-no-label"><IconSVG name="stats" width="16" height="16" /></button><br /><br />
                                <button className="btn btn-link" disabled>btn-link</button><br /><br />
                                <button className="btn-link btn-affirm">
                                    <IconSVG name="saved" width={16} height={16} /><span>Affirm</span>
                                </button><br /><br />
                            </div>
                            <div className="form-item">
                                <button className="btn btn-link secondary">btn-link secondary - medium font(default)</button><br /><br />
                                <button className="btn btn-link secondary regular">btn-link secondary regular - regular font</button><br /><br />
                                <button className="btn btn-link secondary"><IconSVG name="stats" width="16" height="16" /> btn-link secondary</button><br /><br />
                                <button className="btn btn-link secondary" disabled>btn-link secondary</button><br /><br />
                                <button className="btn-link btn-reject btn-danger">
                                    <IconSVG name="close" width={16} height={16} /><span>Reject</span>
                                </button><br /><br />
                            </div>
                            <div className="form-item">
                                <a href="/" className="btn btn-secondary btn-back border-right" >
                                    <IconSVG name="button-arrow-big" width={16} height={12} />
                                </a>  - btn btn-secondary btn-back border-right
                                <br /><br />
                                <button className="btn btn-secondary btn-sidebar-expand border-left">
                                    <IconSVG name="sidebar-expand" width={16} height={16} />
                                </button>  - btn btn-secondary btn-sidebar-expand border-left
                                <br /><br />
                                <button className="btn btn-counter">99</button> - btn btn-counter
                                <br /><br />
                            </div>
                            <div className="form-item">
                                <button className="btn-close">
                                    <IconSVG name="close" width={16} height={16} />
                                </button>
                                <br /><br />
                                <button className="btn btn-sm btn-ghost btn-options">
                                    Options
                                    <IconSVG name="icon-expand" width="16" height="16" />
                                </button>
                            </div>
                        </div>
                        <br /><br />
                        <div className="form-row">
                            <div className="form-item button-kit-item">
                                <button className="btn btn-main btn-full-width">btn btn-main btn-full-width</button><br /><br />
                                <button className="btn btn-main btn-sm btn-full-width">btn btn-main btn-full-width</button><br /><br />
                                <button className="btn btn-ghost btn-full-width">btn btn-ghost btn-full-width</button><br /><br />
                                <button className="btn btn-ghost btn-sm btn-full-width">btn btn-ghost btn-full-width</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {activeTab === tabsUi.statuses &&
                <div className="container-test">
                    <h2>BWIC Statuses</h2>

                    <div className="form-row form-row-inline statuses-uikit">
                        <div className="form-item">
                            <h3>Company Status</h3>
                            <span className="status status-active">Active</span>.status-active<br />
                            <span className="status status-blocked">Blocked</span>.status-blocked<br />
                            <span className="status status-off-platform">Off platform</span>.status-off-platform<br />
                        </div>
                        <div className="form-item">
                            <h3>User Status</h3>
                            <span className="status status-invited">Invited</span>.status-invited<br />
                            <span className="status status-active">Active</span>.status-active<br />
                            <span className="status status-blocked">Blocked</span>.status-blocked<br />
                            <span className="status status-blocked"><span className="pseudo-link">Blocked</span></span>.status-blocked with .pseudo-link<br />
                            <span className="status status-not-invited">Not invited</span>.status-not-invited / .status-notinvited<br />
                        </div>
                        <div className="form-item">
                            <h3>Trade Status</h3>
                            <span className="status status-pending">Pending</span>.status-pending<br />
                            <span className="status status-affirmed">Affirmed</span>.status-affirmed<br />
                            <span className="status status-rejected"><span className="pseudo-link">Rejected</span></span>.status-rejected with .pseudo-link<br />
                        </div>
                    </div>
                    <div className="form-row form-row-inline statuses-uikit">
                        <div className="form-item">
                            <h3>Request to Bid</h3>
                            <span className="status status-pending">Requested</span>.status-pending<br />
                            <span className="status status-affirmed">Accepted</span>.status-affirmed<br />
                            <span className="status status-rejected"><span className="pseudo-link">Rejected</span></span>.status-rejected with .pseudo-link<br />
                            <span className="status status-unrequested">Unrequested</span>.status-unrequested<br />
                        </div>
                        <div className="form-item">
                            <h3>Bid Status</h3>
                            <span className="status status-confirmed">Approved</span>.status-confirmed<br />
                            <span className="status status-confirmed">Auto-approved</span>.status-autoconfirmed<br />
                            <span className="status status-pending">Pending</span>.status-pending<br />
                            <span className="status status-rejected"><span className="pseudo-link">Rejected</span></span>.status-rejected with .pseudo-link<br />
                            <span className="status status-canceled">Canceled</span>.status-canceled<br />
                            <span className="status status-expired">Expired</span>.status-expired<br /><br /><br />
                        </div>
                        <div className="form-item">
                            <h3>Settlement Status</h3>
                            <span className="status status-settled">Settled</span>.status-settled<br />
                            <span className="status status-pending-allocation">Pending Allocation</span>.status-pending-allocation<br />
                            <span className="status status-pending-proceeds">Pending Proceeds</span>.status-pending-proceeds<br />
                            <span className="status status-pending-settlement">Pending Settlement</span>.status-pending-settlement<br />
                            <span className="status status-unsettled">Unsettled</span>.status-unsettled<br /><br /><br />
                        </div>
                    </div>
                    <div className="form-row form-row-inline statuses-uikit">
                        <div className="form-item">
                            <div className="bwic-state-my">My</div> Class full - .bwic-state-my<br />
                            <span className="state-new">NEW</span> Class full - .state-new<br />
                            <div className="bwic-state-ktx">KTX</div> Class full - .bwic-state-ktx<br />
                            <div className="bwic-state-live">LIVE</div> Class full - .bwic-state-live<br />
                            <span className="state-updated">UPD</span> Class full - .state-updated<br />
                        </div>
                        <div className="form-item">
                            <h3>BWIC Label Status</h3>
                            <div className="label-status label-status-bidding">bidding</div>.label-status-bidding<br />
                            <div className="label-status label-status-scheduled">scheduled</div>.label-status-scheduled<br />
                            <div className="label-status label-status-finished">finished</div>.label-status-finished<br />
                            <div className="label-status label-status-canceled">canceled</div>.label-status-canceled<br /><br /><br />
                        </div>
                        <div className="form-item">
                            <div className="bwic-status flex-row">
                                <div className="label-status label-status-bidding short-status">B</div>
                                <div className="bwic-status-label">live</div>
                            </div>
                            <div className="bwic-status flex-row">
                                <div className="label-status label-status-scheduled short-status">S</div>
                                <div className="bwic-status-label">direct</div>
                            </div>
                            <div className="bwic-status flex-row">
                                <div className="label-status label-status-finished short-status">F</div>
                                <div className="bwic-status-label">parsed</div>
                            </div>
                            <div className="bwic-status flex-row">
                                <div className="label-status label-status-canceled short-status">C</div>
                                <div className="bwic-status-label">canceled</div>
                            </div>
                        </div>
                        <div className="form-item">
                            <span className="new-label">new</span> - .new-label
                        </div>
                    </div>


                    <div className="form-row form-row-inline statuses-uikit">
                        <div className="form-item">
                            <h3>AMR Label Status</h3>
                            <span> Main Class - .label-status + additional</span>
                            <br /><br />
                            <div className="label-status label-status-no-amr">-</div> Class - .label-status-no-amr<br />
                            <div className="label-status label-status-bidding">active, bidding</div>
                            Class - .label-status-active, .label-status-bidding<br />
                            <div className="label-status label-status-scheduled">scheduled</div> Class - .label-status-scheduled<br />
                            <div className="label-status label-status-finished">settlement, result, success, submitted, finished, closed, allocation</div>
                            Class - .label-status-settlement, .label-status-result, .label-status-success, .label-status-submitted, .label-status-finished, .label-status-closed, .fullallocation;
                            <br />
                            <div className="label-status label-status-not-scheduled">not scheduled</div> Class - .label-status-not-scheduled<br />
                            <div className="label-status label-status-failed">failed, blocked, above winning margin</div>
                            Class - .label-status-failed, .label-status-blocked, .abovewinningmargin<br />
                            <div className="label-status label-status-priced">priced, allocation correction, new</div>
                            Class - .label-status-priced, .label-status-allocation, .label-status-allocationcorrection, .label-status-new<br />
                            <div className="label-status label-status-draft">draft, pending result, invited, partial allocation</div>
                            Class - .label-status-draft, .label-status-pending, .label-status-pending-result, .label-status-invited, .pendingallocation, .partialallocation<br />
                            <div className="label-status label-status-canceled">canceled, not invited</div> Class - .label-status-canceled, .label-status-not-invited;
                        </div>
                    </div>

                    <br />

                    <h2>Status message</h2>
                    <div className="form-row form-row-inline">
                        <StatusMessageSection type={StatusMessageSectionType.Info}>
                            Info message
                        </StatusMessageSection>
                        <br /> <br />
                        <StatusMessageSection type={StatusMessageSectionType.Error}>
                            Error message
                        </StatusMessageSection>
                        <br /> <br />
                        <StatusMessageSection type={StatusMessageSectionType.Alert}>
                            Alert message
                        </StatusMessageSection>
                        <br /> <br />
                        <StatusMessageSection type={StatusMessageSectionType.Success}>
                            Success message
                        </StatusMessageSection>
                    </div>

                    <br />

                    <h2>News category label</h2>
                    <div className="form-row statuses-uikit">
                        <div className="label-status label-status-news-category label-status-primary">Primary</div>
                        Class - .label-status.label-status-news-category.label-status-primary<br />
                        
                        <div className="label-status label-status-news-category label-status-secondary">Secondary</div>
                        Class - .label-status.label-status-news-category.label-status-secondary<br />

                        <div className="label-status label-status-news-category label-status-research">Research</div>
                        Class - .label-status.label-status-news-category.label-status-research<br />

                        <div className="label-status label-status-news-category label-status-events">Events</div>
                        Class - .label-status.label-status-news-category.label-status-events<br />

                        <div className="label-status label-status-news-category label-status-my-portfolio">My Portfolio</div>
                        Class - .label-status.label-status-news-category.label-status-my-portfolio<br />
                    </div>

                    <br />

                    <h2>Status message icon only</h2>
                    <div className="form-row form-row-inline">
                        <StatusMessageSection type={StatusMessageSectionType.Info} iconOnly>
                            Info message icon only
                        </StatusMessageSection>
                        <br /> <br />
                        <StatusMessageSection type={StatusMessageSectionType.Error} iconOnly>
                            Error message icon only
                        </StatusMessageSection>
                        <br /> <br />
                        <StatusMessageSection type={StatusMessageSectionType.Alert} iconOnly>
                            Alert message icon only
                        </StatusMessageSection>
                        <br /> <br />
                        <StatusMessageSection type={StatusMessageSectionType.Success} iconOnly>
                            Success message icon only
                        </StatusMessageSection>
                    </div>
                </div>
            }
            {activeTab === tabsUi.customControls &&
                <div className="container-test">
                    <h2>Breadcrumbs</h2>
                    <Breadcrumbs>
                        <BreadcrumbsItem route={routes.uiKit} text="Ui Kit" />
                        <BreadcrumbsItem route={routes.uiKit} text="Ui Kit 2" />
                        <BreadcrumbsItem text="Final point" />
                    </Breadcrumbs>

                    <br />

                    <h2>Badges</h2>
                    <div className="form-row form-row-inline statuses-uikit">
                        <div className="form-item">
                            <span className="badge">3</span> &nbsp;&nbsp;
                            <span className="badge">5 <small>NEW</small></span> &nbsp;&nbsp;
                            <span className="badge">B</span>
                        </div>
                    </div>

                    <br />

                    <h2>Counters</h2>
                    <div className="form-row form-row-inline statuses-uikit">
                        <div className="form-item">
                            <span className="counter">9</span> &nbsp;&nbsp;
                            <span className="counter">99</span> &nbsp;&nbsp;
                            <span className="counter">8/9</span> &nbsp;&nbsp;
                            <span className="counter">98/99</span> &nbsp;&nbsp;
                        </div>
                    </div>

                    <br />

                    <h2>Pagination</h2>
                    <div className="form-row form-row-inline">
                        <div className="form-item">
                            <div className="pagination flex-row">
                                <div className="pagination-entries">Showing 1-10 of 301 entries</div>
                                <div className="pagination-cntrls flex-row flex-item-right">
                                    <button className="btn btn-link pagination-cntrls-step-left" disabled=""><i className="icon icon-expand warm-grey"></i></button>

                                    <div className="pagination-cntrls-item selected"><button className="btn btn-link">1</button></div>
                                    <div className="pagination-cntrls-item"><button className="btn btn-link">2</button></div>
                                    <div className="pagination-cntrls-item"><button className="btn btn-link">3</button></div>
                                    <div className="ellipsis">...</div>
                                    <div className="pagination-cntrls-item"><button className="btn btn-link">29</button></div>
                                    <div className="pagination-cntrls-item"><button className="btn btn-link">30</button></div>
                                    <div className="pagination-cntrls-item"><button className="btn btn-link">31</button></div>

                                    <button className="btn btn-link pagination-cntrls-step-right"><i className="icon icon-expand teal-blue"></i></button>
                                </div>
                            </div>
                        </div>
                        <div className="form-item">&nbsp;</div>
                        <div className="form-item">&nbsp;</div>
                        <div className="form-item">&nbsp;</div>
                        <div className="form-item">&nbsp;</div>
                        <div className="form-item">&nbsp;</div>
                    </div>

                    <br />

                    <h2>Indicate value updates</h2>

                    <div className="flex-row">
                        <AnimatedValueUpdate value={value1} />&nbsp;
                        <AnimatedValueUpdate value={value1} msAnimationTime={3000} />&nbsp;
                        <AnimatedValueUpdate value={value1} msAnimationTime={2000} />&nbsp;
                        <AnimatedValueUpdate value={value1} msAnimationTime={1000} />&nbsp;
                        {/* backgroundClass should contain a class with an only property 'background/background-color' */}
                        {/* The examples below are using existing classes which are not directly related to the component */}
                        <AnimatedValueUpdate value={value2} backgroundclassName="label-status-bidding" />&nbsp;
                        <AnimatedValueUpdate value={value3} backgroundclassName="label-status-scheduled" />&nbsp;
                        {/* The following container is used to set the text color */}
                        <div className="status-rejected">
                            <AnimatedValueUpdate value={value3} backgroundclassName="label-status-canceled" />
                        </div>
                        &nbsp;
                        <AnimatedValueUpdate value={value3} msAnimationTime={5000} msAnimationStep={1000} />&nbsp;
                    </div>

                    <br />

                    <h2>Tabs</h2>

                    <ul className="tabs">
                        <li className="tabs-item active"><button className="btn btn-link tabs-item-link">Tab item 1</button></li>
                        <li className="tabs-item"><button className="btn btn-link tabs-item-link">Tab item 2</button></li>
                        <li className="tabs-item"><button className="btn btn-link tabs-item-link">Tab item 3</button></li>
                        <li className="tabs-item"><button className="btn btn-link tabs-item-link">Tab item 4</button></li>
                    </ul>

                    <br />

                    <h2>Progress steps</h2>
                    <ul className="progress-steps">
                        <li className="passed">
                            Start step
                        </li>
                        <li className="is-active">
                            Second step
                        </li>
                        <li>
                            Third step
                        </li>
                        <li>
                            Finish step
                        </li>
                    </ul>
                </div>
            }
            {activeTab === tabsUi.forms &&
                <div className="container-test">
                    <h2>Forms</h2>

                    <div className="form-row">
                        <textarea className="form-control"></textarea>
                    </div>

                    <div className="form-row form-row-inline">
                        <div className="form-item">
                            <label className="form-label" htmlFor="input1">Label</label>
                            <input className="form-control" id="input1" type="text" placeholder="Placeholder" />
                        </div>

                        <div className="form-item">
                            <label className="form-label" htmlFor="input2">Input disabled</label>
                            <input className="form-control" id="input2" placeholder="Placeholder" disabled />
                        </div>

                        <div className="form-item">
                            <label className="form-label" htmlFor="input3">Input with error</label>
                            <div className="form-control-wrapper">
                                <input className="form-control is-invalid" id="input3" placeholder="Placeholder" />
                                <div className="form-error">Error message text</div>
                            </div>
                        </div>

                        <div className="form-item">
                            <label className="form-label text-right" htmlFor="input3top">Input with error on top</label>
                            <div className="form-control-wrapper">
                                <input className="form-control is-invalid" id="input3top" placeholder="Placeholder" />
                                <div className="form-error form-error-top">Error message text</div>
                            </div>
                        </div>

                        <div className="form-item">
                            <label className="form-label" htmlFor="input3right">Input with error on right</label>
                            <div className="form-control-wrapper">
                                <input className="form-control is-invalid" id="input3right" placeholder="Placeholder" />
                                <div className="form-error form-error-right">Error message text</div>
                            </div>
                        </div>
                    </div>

                    <div className="form-row form-row-inline">
                        <div className="form-item">
                            <label className="form-label" htmlFor="input4">Search old markup for AMR. </label>
                            <input className="form-control form-control-search" id="input4" type="text" style={{ width: '400px' }} placeholder="Placeholder" />
                        </div>
                        <div className="form-item">
                            <label className="form-label">Search for BWIC. Can use with chips. </label>
                            <div className="control-search-wrapper flex-none">
                                <div className="form-control-search-btn flex-none">
                                    <i className="icon icon-search" />
                                </div>
                                <input
                                    className="form-control form-control-search"
                                    type="text"
                                    placeholder="Search"
                                />
                                <button className="btn-close">
                                    <IconSVG name="close" width={16} height={16} />
                                </button>
                            </div>
                        </div>

                        <div className="form-item" style={{ height: '200px' }}>
                            <label className="form-label">Search for BWIC. With chips and lookup. </label>
                            <div className="control-search-wrapper flex-none">
                                <div className="form-control-search-btn flex-none">
                                    <i className="icon icon-search" />
                                </div>
                                <div className="chip-list">
                                    <div className="chip">
                                        <div className="chip-content">
                                            <span className="chip-text">BSNL 2004-1A A</span>
                                            <button type="button" className="chip-button">
                                                <IconSVG name="close" width={16} height={16} />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="chip">
                                        <div className="chip-content">
                                            <span className="chip-text">ANGE</span>
                                            <button type="button" className="chip-button">
                                                <IconSVG name="close" width={16} height={16} />
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <input
                                    className="form-control form-control-search"
                                    type="text"
                                    placeholder=""
                                />
                                <button className="btn-close">
                                    <IconSVG name="close" width={16} height={16} />
                                </button>
                                <div className="search-lookup">
                                    <table>
                                        <tbody>
                                            <tr className="active">
                                                <td className="col-main">
                                                    <strong>Custom search:</strong>
                                                </td>
                                                <td className="col-ellipsis">us12</td>
                                                <td></td>
                                            </tr>
                                            <tr>
                                                <td className="col-main">NEUB 2018-30A A1</td>
                                                <td>64132MAA6</td>
                                                <td>NR</td>
                                            </tr>
                                            <tr>
                                                <td className="col-main">DRSLF 2018-71A E</td>
                                                <td>26252GAA2</td>
                                                <td>BBB</td>
                                            </tr>
                                            <tr>
                                                <td className="col-main">DRSLF 2018-71A D</td>
                                                <td>26252HAG7</td>
                                                <td>A</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="form-row form-row-inline">
                        <div className="form-item">
                            <label className="form-label" htmlFor="input1">Input with bookmark</label>
                            <div className="form-control-changed">
                                <input type="text" className="form-control text-right" />
                            </div>
                        </div>

                        <div className="form-item">
                            <label className="form-label" htmlFor="input1">Input with bookmark with error</label>
                            <div className="form-control-changed form-control-error">
                                <input type="text" className="form-control text-right" />
                                <div className="form-error">Error message text</div>
                            </div>
                        </div>
                    </div>

                    <div className="form-row form-row-inline">
                        <div className="form-item">
                            <label className="form-label" htmlFor="input1">Label</label>
                            <select className="form-control form-select">
                                <option>select 1</option>
                                <option>select 2</option>
                                <option>select 3</option>
                            </select>
                        </div>

                        <div className="form-item">
                            <label className="form-label" htmlFor="input2">Select disabled</label>
                            <select className="form-control form-select" disabled>
                                <option>select 1</option>
                                <option>select 2</option>
                                <option>select 3</option>
                            </select>
                        </div>

                        <div className="form-item">
                            <label className="form-label" htmlFor="input3">Select with error</label>
                            <div className="form-control-wrapper">
                                <select className="form-control form-select is-invalid">
                                    <option>select 1</option>
                                    <option>select 2</option>
                                    <option>select 3</option>
                                </select>
                                <div className="form-error">Error message text</div>
                            </div>
                        </div>
                    </div>

                    <div className="form-row form-row-inline">
                        <div className="form-item">
                            <label className="form-label">Stepper</label>

                            <div className="control-stepper">
                                <button className="btn-stepper btn-left">
                                    <IconSVG name="minus" width={16} height={16} />
                                </button>
                                <input className="form-control-stepper" type="text" />
                                <button className="btn-stepper btn-right">
                                    <IconSVG name="plus" width={16} height={16} />
                                </button>
                            </div>
                        </div>

                        <div className="form-item">
                            <label className="form-label">Stepper focused</label>

                            <div className="control-stepper is-focus">
                                <button className="btn-stepper btn-left">
                                    <IconSVG name="minus" width={16} height={16} />
                                </button>
                                <input className="form-control-stepper" type="text" />
                                <button className="btn-stepper btn-right">
                                    <IconSVG name="plus" width={16} height={16} />
                                </button>
                            </div>
                        </div>

                        <div className="form-item">
                            <label className="form-label">Stepper disabled</label>

                            <div className="control-stepper disabled">
                                <button className="btn-stepper btn-left">
                                    <IconSVG name="minus" width={16} height={16} />
                                </button>
                                <input className="form-control-stepper" type="text" disabled />
                                <button className="btn-stepper btn-right">
                                    <IconSVG name="plus" width={16} height={16} />
                                </button>
                            </div>
                        </div>

                        <div className="form-item">
                            <label className="form-label">Stepper with error</label>

                            <div className="form-control-wrapper">
                                <div className="control-stepper is-invalid">
                                    <button className="btn-stepper btn-left">
                                        <IconSVG name="minus" width={16} height={16} />
                                    </button>
                                    <input className="form-control-stepper" type="text" />
                                    <button className="btn-stepper btn-right">
                                        <IconSVG name="plus" width={16} height={16} />
                                    </button>
                                </div>
                                <div className="form-error">Error message text</div>
                            </div>
                        </div>

                        <div className="form-item">
                            <label className="form-label">Form control wrapper with an optional error</label>

                            <FormControlWrapper error="Error message">
                                <input className="form-control is-invalid" />
                            </FormControlWrapper>
                        </div>
                    </div>

                    <br /><br />

                    <div className="form-row  form-row-inline">
                        <div className="form-item">
                            <label className="form-label">Toggle Off</label>
                            <Toggle value={false} />
                        </div>
                        <div className="form-item">
                            <label className="form-label">Toggle On</label>
                            <Toggle value={true} />
                        </div>
                        <div className="form-item">
                            <label className="form-label">Toggle Disabled</label>
                            <Toggle value={false} disabled />
                        </div>
                    </div>

                    <br /><br />

                    <div className="form-row  form-row-inline">
                        <div className="form-item">
                            <Checkbox
                                label="Checkbox"
                                onChange={() => {}}
                            />
                        </div>
                        <div className="form-item">
                            <Checkbox
                                checked={true}
                                label="Checkbox checked"
                                onChange={() => {}}
                            />
                        </div>
                        <div className="form-item">
                            <Checkbox
                                label="Checkbox disabled"
                                disabled
                                onChange={() => {}}
                            />
                        </div>
                        <div className="form-item">
                            <Checkbox
                                label="Checkbox checked disabled"
                                checked={true}
                                disabled
                                onChange={() => {}}
                            />
                        </div>
                        <div className="form-item">
                            <Checkbox
                                partially={true}
                                label="Checkbox indeterminate"
                                onChange={() => {}}
                            />
                        </div>
                    </div>

                    <div className="form-row  form-row-inline">
                    <div className="form-item">
                            <RadioButton
                                label="Radio"
                                onChange={() => {}}
                            />
                        </div>
                        <div className="form-item">
                            <RadioButton
                                checked={true}
                                label="Radio checked"
                                onChange={() => {}}
                            />
                        </div>
                        <div className="form-item">
                            <RadioButton
                                label="Radio disabled"
                                disabled
                                onChange={() => {}}
                            />
                        </div>
                        <div className="form-item">
                            <RadioButton
                                label="Radio checked disabled"
                                checked={true}
                                disabled
                                onChange={() => {}}
                            />
                        </div>
                        <div className="form-item">
                            <label className="form-label">Radiobuttons group</label>
                            <RadioButtonGroup
                                options={[{ label: 'Radio 1', value: '1' }, { label: 'Radio 2', value: '2' }]}
                                value="2"
                            />
                        </div>
                        <div className="form-item">
                            <label className="form-label">Radiobuttons group row</label>
                            <RadioButtonGroup
                                className="radio-group-row"
                                options={[{ label: 'Radio 1', value: '1' }, { label: 'Radio 2', value: '2' }]}
                                value="2"
                            />
                        </div>
                    </div>

                    <div className="form-row  form-row-inline">
                        <div className="form-item">
                            <label className="form-label">Year selector</label>

                            <YearSelector
                                years={availableYears}
                                selectedYear={2021}
                                currentYearPrefix="YTD"
                                onChange={() => null}
                            />
                        </div>

                        <div className="form-item">
                            <label className="form-label">Dropdown ghost</label>

                            <DropDownList
                                items={types.map(({ value, text, selected }) => ({
                                    value,
                                    text,
                                    selected,
                                }))}
                                onChange={() => null}
                                className="custom-drop-down-ghost"
                            />
                        </div>

                        <div className="form-item">
                            <label className="form-label">Dropdown</label>

                            <DropDownList
                                items={types.map(({ value, text, selected }) => ({
                                    value,
                                    text,
                                    selected,
                                }))}
                                onChange={() => null}
                            />
                        </div>
                    </div>

                    <br />

                    <div className="form-row form-row-inline">
                        <div className="form-item">
                            <label className="form-label">Control group slide</label>

                            <ButtonSwitch
                                className="control-group-slide"
                                sourceItems={[
                                    { title: 'Deal View', key: '1' },
                                    { title: 'Class View', key: '2' },
                                ]}
                                selectedValue="1"
                                onSelectionChange={(_) => _}
                            />
                        </div>

                        <div className="form-item">
                            <label className="form-label">Control group Type 01</label>

                            <ButtonSwitch
                                sourceItems={[
                                    { title: 'Test 1', key: '1' },
                                    { title: 'Test 2', key: '2' }
                                ]}
                                selectedValue="2"
                                onSelectionChange={() => { }}
                                className="control-group-type01"
                            />
                        </div>

                        <div className="form-item">
                            <label className="form-label">
                                Control-radio-square
                            </label>
                            <div className="control-radio-square">
                                <input
                                    type="radio"
                                    id="company-broker"
                                    name="companyType"
                                    value="1"
                                    required
                                />
                                <label className="control-label" htmlFor="company-broker">Broker-Dealer</label>
                            </div>
                            <div className="control-radio-square">
                                <input
                                    type="radio"
                                    id="company-manager"
                                    name="companyType"
                                    value="0"
                                />
                                <label className="control-label" htmlFor="company-manager">Asset Manager</label>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {activeTab === tabsUi.filters &&
                <div className="container-test">
                    <div className="form-row form-row-inline">
                        <div className="form-item">
                            <h2>Filters</h2>
                            <FilterPanel>
                                <FilterSection>
                                    <FilterMultiPicker
                                        disabled={false}
                                        sourceItems={types}
                                        required={false}
                                        onChangeItemSelection={() => { }}
                                    />
                                </FilterSection>
                            </FilterPanel>
                        </div>

                        <div className="form-item">
                            <h2>Dashboard Filters</h2>
                            <FilterPanel>
                                <FilterSection>
                                    <div className="filter-group filter-group-type01 flex-row">
                                        {types.map(({ value, text, selected }) =>
                                            <FilterSwitch
                                                key={value}
                                                caption={text}
                                                selected={selected}
                                                disabled={false}
                                                onClick={() => { }}
                                            />)}
                                    </div>
                                </FilterSection>
                            </FilterPanel>
                        </div>
                    </div>
                </div>
            }

            {activeTab === tabsUi.panels &&
                <div className="container-test">
                    <h2>Banner</h2>
                    <div className="banner">
                        <IconSVG name="info" width={16} height={16} />
                        Banner Content
                        <button className="btn-close">
                            <IconSVG name="close" width={16} height={16} />
                        </button>
                    </div>

                    <br />

                    <div className="banner">
                        <IconSVG name="info" width={16} height={16} />
                        Banner Content
                    </div>

                    <br />

                    <h2>Panels with information .panel-info</h2>

                    <div className="form-row form-row-inline">
                        <div className="form-item">
                            <h4>.panel-info .panel-info-note</h4>

                            <div className="panel-info panel-info-note">
                                <strong>NOTE:</strong> This step is optional. You may skip it.
                            </div>
                        </div>

                    </div>

                    <br />

                    <div className="form-row form-row-inline">
                        <div className="form-item">
                            <h4>.panel-info .panel-info-primary</h4>

                            <div className="panel-info panel-info-primary">
                                <p className="title">
                                    Typing your name is equivalent to your handwritten signature.
                                </p>
                                <p>
                                    By typing my signature and sending it via the Internet, I acknowledge that I have read
                                    and understand all information provided during the application process; that I intend
                                    KopenTech Capital Markets to rely upon it; that I intend to be bound thereby; and that
                                    I understand and agree that my electronic signature is the equivalent of a manual
                                    written signature.
                                </p>
                            </div>
                        </div>
                    </div>

                    <br />

                    <div className="form-row form-row-inline">
                        <div className="form-item">
                            <h4>.panel-info .panel-info-primary with placeholder text .panel-info-text-empty</h4>

                            <div className="panel-info panel-info-primary">
                                <p className="panel-info-text-empty">
                                    There are no orders yet. You can add a new one by clicking a button below.
                                </p>
                            </div>
                        </div>
                    </div>

                    <br />

                    <div className="form-row form-row-inline">
                        <div className="form-item">
                            <h4>.panel-info .panel-info-secondary</h4>

                            <div className="panel-info panel-info-secondary">
                                <span className="title"></span>
                                <span>
                                    Typing your name is equivalent to your handwritten signature.
                                </span>
                            </div>
                        </div>
                    </div>

                    <br />

                    <div className="form-row form-row-inline">
                        <div className="form-item">
                            <h4>.panel-info .panel-info-tertiary with dropped content .panel-info-status-content and standart tooltip </h4>

                            <div className="panel-info panel-info-tertiary" style={{ width: 510 }}>
                                <div className="panel-info-status-content" title={`Auctions are scheduled. You can submit bids on 8:00 AM EST`}>
                                    <span className="title">
                                        <span className="text-black">Auctions are scheduled. </span><span className="text-medium">You can submit bids on dateFormat</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" className="status-clock"><path d="M11 9.085a1.5 1.5 0 0 1 .854 2.062l2.742 2.742a.5.5 0 0 1-.707.707l-2.742-2.742A1.5 1.5 0 0 1 10 9.086V4.5a.5.5 0 0 1 1 0v4.585zM10.5 19a8.5 8.5 0 1 0 0-17 8.5 8.5 0 0 0 0 17zm0 2C4.701 21 0 16.299 0 10.5S4.701 0 10.5 0 21 4.701 21 10.5 16.299 21 10.5 21z" /></svg>
                                    </span>
                                    <span className="text-black"><b>8:00 AM EST</b></span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <br />

                    <div className="form-row form-row-inline">
                        <div className="form-item">
                            <h4>.panel-info-sm .panel-info-sm-primary</h4>

                            <div className="panel-info-sm panel-info-sm-primary">
                                <i className="icon icon-sync-success"></i>
                                <span>Orders are synced with bids</span>
                            </div>
                        </div>
                    </div>

                    <br />

                    <div className="form-row form-row-inline">
                        <div className="form-item">
                            <h4>.panel-info-sm .panel-info-sm-secondary</h4>

                            <div className="panel-info-sm panel-info-sm-secondary">
                                <i className="icon icon-sync-reject"></i>
                                <span>Orders are not synced with bids</span>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {activeTab === tabsUi.popoverTooltipModal &&
                <div className="container-test">
                    <h2>Popovers and tooltips</h2>

                    <div className="form-row form-row-inline">
                        <div className="form-item">
                            <h3>popover-help</h3>
                            <div className="sub-header-top-panel">
                                <div className="sub-header-popover flex-row">
                                    <div className="popover popover-help flex-row">
                                        <div className="flex-none"><i className="icon icon-lock"></i><b>You need an invite from the seller</b></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="form-row form-row-inline">
                        <div className="form-item">
                            <h3>Popover</h3>
                            <div className="popover popover-wide popover-md" style={{ position: 'relative', top: 0, left: 0 }}>
                                <div className="popover-header">
                                    <h1>Rejected Reason</h1>
                                    <button className="btn-close">
                                        <IconSVG name="close" width={16} height={16} />
                                    </button>
                                </div>
                                <div className="popover-body">
                                    <div className="popover-content">
                                        <div className="popover-simple-text">test</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form-item">
                            <h3>Popovers width variety</h3>
                            <ul>
                                <li>.popover-wide - 653px</li>
                                <li>.popover-bwic-history - 564px</li>
                                <li>.popover-security-info - 502px</li>
                                <li>.popover-wide-sm - 443px</li>
                                <li>.popover-md - 360px</li>
                                <li>.popover-onboarding - 244px</li>
                            </ul>
                        </div>
                    </div>

                    <div className="form-row form-row-inline">
                        <div className="form-item">
                            <div className="popover popover-grid-help" style={{ position: 'relative' }}>
                                <div className="popover-header">
                                    <p className="text-medium"><IconSVG name="add-contour" width={16} height={16} /> You can add your securities in the following ways</p>
                                    <button className="btn-close">
                                        <IconSVG name="close" width={16} height={16} />
                                    </button>
                                </div>
                                <div className="popover-body">
                                    <ol>
                                        <li>Manually</li>
                                        <li>Upload file</li>
                                        <li>Copy and paste from Excel</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="form-row form-row-inline">
                        <div className="form-item">
                            <div className="popover popover-help" style={{ width: '322px' }}>
                                <IconSVG name="email" width="16" height="16" />
                                BWIC will be sent to 1 from 1 company
                            </div>
                        </div>

                        <div className="form-item">
                            Icon + tooltip
                            <OnHoverTooltip overlay="Text for tooltip">
                                <IconSVG name="help" width={16} height={16} />
                            </OnHoverTooltip>
                        </div>
                        <div className="form-item">
                            <OnHoverTooltip overlay={
                                <>
                                    <p>First line</p>
                                    <p>Second line</p>
                                </>
                            }>
                                <span>Tooltip with two text rows</span>
                            </OnHoverTooltip>
                        </div>
                        <div className="form-item">
                            <OnHoverTooltip overlay="Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum ">
                                <span>Tooltip with long text</span>
                            </OnHoverTooltip>
                        </div>
                    </div>

                    <br />

                    <h2>Modals</h2>

                    <div className="form-row form-row-inline">
                        <div className="form-item">
                            <div className="modal modal-test">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h1>Confirmation</h1>
                                        <button className="btn-close">
                                            <IconSVG name="close" width={16} height={16} />
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <p className="status-message alert">
                                            <i className="icon icon-warning"></i><span className="status-message-cnt">Are you sure you want to leave? All changes will be discarded.</span>
                                        </p>
                                    </div>
                                    <div className="modal-footer">
                                        <button className="btn btn-ghost">No</button>
                                        <button className="btn btn-main">Yes</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {activeTab === tabsUi.svgImages &&
                <div className="container-test">
                    <h2>SVG Images</h2>
                    <div className="form-row form-row-inline">
                        <div className="form-item">
                            <ImageSVG name="resetPassword" width={326} height={208} />&nbsp;&nbsp;&nbsp; name="resetPassword" width={326} height={208} <br /><br />
                            <ImageSVG name="linkExpired" width={326} height={208} />&nbsp;&nbsp;&nbsp; name="linkExpired" width={326} height={208} <br /><br />
                            <ImageSVG name="no-data" width={164} height={128} />&nbsp;&nbsp;&nbsp; name="no-data" width={164} height={128} <br /><br />
                            <ImageSVG name="no-data2" width={164} height={128} />&nbsp;&nbsp;&nbsp; name="no-data2" width={164} height={128} <br /><br />
                            <ImageSVG name="no-portfolios-small" width={164} height={128} />&nbsp;&nbsp;&nbsp; name="no-portfolios-small" width={164} height={128} <br /><br />
                            <ImageSVG name="no-data-on-security-found" width={164} height={128} />&nbsp;&nbsp;&nbsp; name="no-data-on-security-found" width={164} height={128}<br /><br />
                            <ImageSVG name="feature-available02" width={162} height={99} />&nbsp;&nbsp;&nbsp; name="feature-available02" width={162} height={99}<br /><br />
                        </div>
                        <div className="form-item">
                            <ImageSVG name="NoMessages" width={216} height={122} />&nbsp;&nbsp;&nbsp; name="NoMessages" width={216} height={122} <br /><br />
                            <ImageSVG name="sendFormState" width={293} height={184} />&nbsp;&nbsp;&nbsp; name="sendFormState" width={293} height={184} <br /><br />
                            <ImageSVG name="start-search-by-ticker" width={164} height={128} />&nbsp;&nbsp;&nbsp; name="start-search-by-ticker" width={164} height={128} <br /><br />
                            <ImageSVG name="no-security-search-result-found" width={164} height={128} />&nbsp;&nbsp;&nbsp; name="no-security-search-result-found" width={164} height={128}<br /><br />
                            <ImageSVG name="no-avatar" width={120} height={120} />&nbsp;&nbsp;&nbsp; name="no-avatar" width={120} height={120}<br /><br />
                            <ImageSVG name="difficulties-in-load-data" width={164} height={128} />&nbsp;&nbsp;&nbsp; name="difficulties-in-load-data" width={164} height={128}<br /><br />
                        </div>
                    </div>
                </div>
            }

            {activeTab === tabsUi.tables &&
                <div className="container-test">
                    <div className="form-row form-row-inline">
                    <div className="form-item">
                        <h2>Data-List Tables columns widths</h2>
                        <br />
                            <p>For
                                <b> FREEZE Data-List</b> use classes <b>.data-list-freeze and .data-list-striped</b>.
                                These classes add by default with flag Sticky = true
                            </p>
                            <br />
                            <h3>This classes could be using with freeze tables and with additional class for simple tables:</h3>
                            <p><b>.data-list-striped</b> - using additinal to class .data-list</p>
                            <p><b>.data-list-cell</b> - 80px - BASE</p>
                            <p><b>.data-list-cell-symbol</b> - 10px</p>
                            <p><b>.data-list-cell-icon</b> - 16px</p>
                            <p><b>.data-list-cell-xxxs</b> - 24px</p>
                            <p><b>.data-list-cell-xxs</b> - 32px</p>
                            <p><b>.data-list-cell-xxs-02</b> - 48px</p>
                            <p><b>.data-list-cell-xs</b> - 60px</p>
                            <p><b>.data-list-cell-xs-sm</b> - 75px</p>
                            <p><b>.data-list-cell-sm-01</b> - 100px</p>
                            <p><b>.data-list-cell-sm</b> - 110px</p>
                            <p><b>.data-list-cell-md</b> - 125px</p>
                            <p><b>.data-list-cell-lg</b> - 150px</p>
                            <p><b>.data-list-cell-lg-02</b> - 180px</p>
                            <p><b>.data-list-cell-xl</b> - 200px</p>
                            <p><b>.data-list-cell-xl-xxl</b> - 240px</p>
                            <p><b>.data-list-cell-xxl</b> - 280px</p>
                            <p><b>.data-list-cell-xxxl</b> - 315px</p>
                            <p><b>.data-list-cell-xxxxl</b> - 400px</p>
                            <p><b>.data-list-cell-xs-flexible</b> - min-width 60px to unlim </p>
                            <p><b>.data-list-cell-md-flexible</b> - min-width 125px to unlim </p>
                            <p><b>.data-list-cell-lg-02-flexible</b> - min-width 180 to unlim </p>
                            <p><b>.data-list-cell-xl-flexible</b> - min-width 200px to unlim </p>
                            <p><b>.data-list-cell-xxl-flexible</b> - min-width 280px to unlim </p>
                            <p><b>.data-list-cell-xxxl-flexible</b> - min-width 315px to unlim </p>
                            <p><b>.data-list-cell-text-full</b> - white-space: normal</p>
                        </div>
                    <div className="form-item">
                        <h2>Grid (excel) columns widths</h2>
                        <br />
                        <p><b>.cell-no-data:first-child; .cell-number</b> - 35px</p>
                        <p><b>$grid-column-width-xs</b> - 80px</p>
                        <p><b>$grid-column-width-sm</b> - 100px</p>
                        <p><b>$grid-column-width-md</b> - 120px</p>
                        <p><b>$grid-column-width-md-02</b> - 150px</p>
                        <p><b>$grid-column-width-lg</b> - 170px</p>
                        <p><b>$grid-column-width-lg-02</b> - 200px</p>
                        <p><b>$grid-column-width-xl</b> - 240px</p>
                        <p><b>$grid-column-width-xl-02</b> - 260px</p>
                        <p><b>$grid-column-width-xxl</b> - 280px</p>
                        <p><b>$grid-column-width-xxxl</b> - 500px</p>
                    </div>
                    </div>
                </div>
            }
        </>
    );
}

export default UiKit;
